@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,600,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0px;
  padding: 0px;
  background: #f3f3f4;
}

h1 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.25rem;
  font-size: 1.75em;
}
h2 {
  font-size: 1.25em;
  font-family: "Poppins", sans-serif;
}
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
