@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,600,700&display=swap);
.beat div {
  -webkit-animation: beat 5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: beat 5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.beat div img {
  width: 15rem;
}

.beat div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.beat div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@-webkit-keyframes beat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  85% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  90% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  95% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes beat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  85% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  90% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  95% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0px;
  padding: 0px;
  background: #f3f3f4;
}

h1 {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.25rem;
  font-size: 1.75em;
}
h2 {
  font-size: 1.25em;
  font-family: "Poppins", sans-serif;
}
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

