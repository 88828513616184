.beat div {
  animation: beat 5s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.beat div img {
  width: 15rem;
}

.beat div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.beat div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes beat {
  from {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  85% {
    transform: scale(0.85);
  }
  90% {
    transform: scale(0.95);
  }
  95% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}
